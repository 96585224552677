<template>
	<div>我是清除缓存页面</div>
</template>
<script>
import VueCookies from 'vue-cookies';
export default {
	props: {},
	components: {},
	data() {
		return {
			msg: 'Hello World!',
			active: 0
		};
	},
	created() {
		//todo 张工模拟登录
		localStorage.removeItem('scope');
		localStorage.removeItem('userInfo');
		localStorage.removeItem('tenantId');
		localStorage.removeItem('refresh_token');
		localStorage.removeItem('appid');
		localStorage.removeItem('access_token');
		localStorage.removeItem('token');
		VueCookies.set('openId', null); //return this

		// localStorage.setItem('scope', undefined);
		// localStorage.setItem('userInfo');
		// localStorage.setItem('tenantId', '000000');
		// localStorage.setItem('refresh_token', '61_nYDfVMp3FYA7wkK989MiySgZ0Z43F1FaafwBN7ApQLUq9_bnGnPLKpPsO78c_MhMSycGk3uZab3ht68PyPMoOXzjfZ5xYIt84q-MCDQKSYY');
		// localStorage.setItem('appid', 'wxd21e3ea9737083fc');
		// localStorage.setItem('access_token', '61_TmW70GanHhmrRlTGtOA4Q0u7kuo-7aSUHZEbwA_wbwzczs4yys7UZkinSMJp_23-4L9Q-X8KRgIhwbc7kPZ_ct0y6j-0Q31bX04krl-cn5c');
		// localStorage.setItem(
		// 	'token',
		// 	'eyJ0eXBlIjoiSldUIiwiYWxnIjoiSFMyNTYifQ.eyJJZEVudGl0eSI6InN0YWZmIiwidXVpZCI6IjUzMTdCRUZBRUFEQTQ1Mjk5NUQwRjhCMjU1QTZFRkZFIiwidGVuYW50SWQiOiIwMDAwMDAiLCJ1c2VyTmFtZUlkIjoiNTMxN0JFRkFFQURBNDUyOTk1RDBGOEIyNTVBNkVGRkUiLCJ1c2VyTmFtZSI6IuW8oOW3pSIsIm9yZ0lkIjoiIiwicm9sZUlkIjoiIiwiZXhwIjoxNjY1MzAwNzc4LCJuYmYiOjE2NjUxMjc5Nzh9.vz0IQ1m-S7QW_mnPWoZ_v0lphf6J2_yt3CtwtGpIY3Y'
		// );
		// VueCookies.set('openId', 'olcRP53B8-4KiLWgoWLRTnamWpzc'); //return this
	},
	mounted() {},
	methods: {}
};
</script>
<style lang="scss" scoped></style>
